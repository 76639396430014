import Vue from 'vue'
import Router from 'vue-router'
import ThemeNine from '@/themes/theme-nine'
import ThemeNineEnglish from '@/themes/theme-nine-english'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: ThemeNine
    },
    {
      path: '/en',
      name: 'HomeEnglish',
      component: ThemeNineEnglish
    },
  ]
})