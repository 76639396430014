<template>
  <section id="contattaci" class="contact-area ptb_100">
    <div class="container">
      <div class="row justify-content-between align-items-center">
        <div class="col-12 col-lg-5">
          <!-- Section Heading -->
          <div class="section-heading text-center mb-3">
            <h2>Contact Us</h2>
            <p class="d-none d-sm-block mt-4">
              We are ready to help your company to grow more and more. Contact
              us!
            </p>
            <p class="d-block d-sm-none mt-4">
              We are ready to help your company to grow more and more. Contact
              us!
            </p>
          </div>
          <!-- Contact Us -->
          <div class="contact-us">
            <ul>
              <!-- Contact Info -->
              <li
                class="contact-info color-1 bg-hover active hover-bottom text-center p-5 m-3"
              >
                <span><i class="fas fa-mobile-alt fa-3x"></i></span>
                <a class="d-block my-2" href="#">
                  <h3>+39 333 221 1655</h3>
                </a>
              </li>
              <!-- Contact Info -->
              <li
                class="contact-info color-3 bg-hover active hover-bottom text-center p-5 m-3"
              >
                <span><i class="fas fa-envelope-open-text fa-3x"></i></span>
                <a class="d-none d-sm-block my-2" href="#">
                  <h3>info@texer.io</h3>
                </a>
                <a class="d-block d-sm-none my-2" href="#">
                  <h3>info@texer.io</h3>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 pt-4 pt-lg-0">
          <!-- Contact Box -->
          <div class="contact-box text-center">
            <!-- Contact Form -->
            <form
              ref="contactForm"
              data-success-msg="The message was sent"
              data-err-msg="Oops! something went wrong. Please retry."
              id="texer-contact-form"
              name="texer-contact-form"
              netlify
              netlify-honeypot="bot-field"
              @submit.prevent="contactFormValidation()"
            >
              <input
                type="hidden"
                name="form-name"
                value="texer-contact-form"
              />
              <div class="hidden-inputs">
                <label>
                  Don’t fill this out if you’re human:
                  <input name="bot-field" />
                </label>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="control form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="name"
                      placeholder="Name*"
                      minlength="3"
                      required
                    />
                    <!-- validation errors messages -->
                    <div class="errors-msgs">
                      <input
                        class="required"
                        type="hidden"
                        value="The name is mandatory"
                      />
                      <input
                        class="minLength"
                        type="hidden"
                        value="The name must have at least 3 characters"
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="company"
                      placeholder="Company"
                    />
                  </div>
                  <div class="control form-group">
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      placeholder="Email*"
                      required
                    />
                    <!-- validation errors messages -->
                    <div class="errors-msgs">
                      <input
                        class="required"
                        type="hidden"
                        value="The mails is mandatory"
                      />
                      <input
                        class="invalid"
                        type="hidden"
                        value="Please, inform a valid email"
                      />
                    </div>
                  </div>
                  <div class="control form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="telephone"
                      placeholder="Telephone"
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="control form-group">
                    <textarea
                      class="form-control"
                      name="message"
                      placeholder="Message*"
                      required
                    ></textarea>

                    <!-- validation errors messages -->
                    <div class="errors-msgs">
                      <input
                        class="required"
                        type="hidden"
                        value="The message is mandatory"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="btn btn-bordered active btn-block mt-3"
                  >
                    <span class="text-white pr-3"
                      ><i class="fas fa-paper-plane"></i></span
                    >Send Message
                  </button>
                </div>
              </div>
            </form>
            <p v-if="successMessage" class="form-message m-3 p-1 form-success">
              {{ successMessage }}
            </p>
            <p v-if="errorMessage" class="form-message m-3 p-1 form-danger">
              {{ errorMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable no-useless-escape */
export default {
  data() {
    return {
      successMessage: "",
      errorMessage: "",
      notifications: [],
    };
  },
  methods: {
    // contact form validation
    contactFormValidation() {
      // contact form
      const contactForm = this.$refs.contactForm;

      // form controls
      const name = contactForm.querySelector('input[name="name"]');
      const email = contactForm.querySelector('input[name="email"]');
      const message = contactForm.querySelector("textarea");

      // form validation status
      let errors = {
        name: { required: true, minLength: true },
        email: { required: true, invalid: true },
        message: { required: true },
      };

      /* --------------- */
      /* name validation */
      /* --------------- */

      // required validation
      if (name.value === "") {
        errors.name.required = true;
        this.setNotify({
          id: "nameRequired",
          className: "danger",
          msg: name.closest(".control").querySelector(".errors-msgs .required")
            .value,
        });
      } else {
        errors.name.required = false;
        this.dismissNotify("nameRequired");
      }

      // minlength validation
      if (
        name.value.length > 0 &&
        name.value.length < name.getAttribute("minlength")
      ) {
        errors.name.minLength = true;
        this.setNotify({
          id: "nameMinLength",
          className: "danger",
          msg: name.closest(".control").querySelector(".errors-msgs .minLength")
            .value,
        });
      } else {
        errors.name.minLength = false;
        this.dismissNotify("nameMinLength");
      }

      // toggle invalid errors & style classes
      if (Object.keys(errors.name).some((err) => errors.name[err] === true)) {
        name.classList.remove("valid");
        name.classList.add("invalid");
      } else {
        name.classList.remove("invalid");
        name.classList.add("valid");
      }

      /* ---------------- */
      /* email validation */
      /* ---------------- */

      // required validation
      if (email.value === "") {
        errors.email.required = true;
        this.setNotify({
          id: "emailRequired",
          className: "danger",
          msg: email.closest(".control").querySelector(".errors-msgs .required")
            .value,
        });
      } else {
        errors.email.required = false;
        this.dismissNotify("emailRequired");
      }

      // email validation
      if (
        email.value.length > 0 &&
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email.value
        )
      ) {
        errors.email.invalid = true;
        this.setNotify({
          id: "emailInvalid",
          className: "danger",
          msg: email.closest(".control").querySelector(".errors-msgs .invalid")
            .value,
        });
      } else {
        errors.email.invalid = false;
        this.dismissNotify("emailInvalid");
      }

      // toggle invalid errors & style classes
      if (Object.keys(errors.email).some((err) => errors.email[err] === true)) {
        email.classList.remove("valid");
        email.classList.add("invalid");
      } else {
        email.classList.remove("invalid");
        email.classList.add("valid");
      }

      /* ------------------ */
      /* message validation */
      /* ------------------ */

      // required validation
      if (message.value === "") {
        errors.message.required = true;
        this.setNotify({
          id: "messageRequired",
          className: "danger",
          msg: message
            .closest(".control")
            .querySelector(".errors-msgs .required").value,
        });
      } else {
        errors.message.required = false;
        this.dismissNotify("messageRequired");
      }

      // toggle invalid errors & style classes
      if (
        Object.keys(errors.message).some((err) => errors.message[err] === true)
      ) {
        message.classList.remove("valid");
        message.classList.add("invalid");
      } else {
        message.classList.remove("invalid");
        message.classList.add("valid");
      }

      // send the message if the form is valid
      // !Object.values(errors).some((control) =>
      //   Object.values(control).some(Boolean)
      // ) && this.sendNetlify(contactForm);
      this.sendNetlify(contactForm);
    },

    sendNetlify(form) {
      let formData = new FormData(form);

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          // show success message
          this.setSuccessMessage(form.getAttribute("data-success-msg"));

          // reset all form inputs
          form.reset();

          // remove inputs valid classes
          form
            .querySelectorAll(".valid")
            .forEach((el) => el.classList.remove("valid"));
        })
        .catch((error) => {
          console.error(error);
          this.setErrorMessage(form.getAttribute("data-err-msg"));
        });
    },

    setSuccessMessage(message) {
      this.successMessage = message;
    },

    setErrorMessage(message) {
      this.errorMessage = message;
    },

    // show messages by toast notifications
    setNotify({ id, className, msg, time }) {
      const notify = {
        id: id || `${Date.now()}${this.notifications.length}`,
        className,
        msg,
        time,
      };

      if (id) {
        !this.notifications.some((e) => e.id === id) &&
          this.notifications.push(notify);
      } else {
        this.notifications.push(notify);
      }

      // remove this notification from the array after (n) seconds
      time && setTimeout(() => this.dismissNotify(notify.id), time);
    },

    // dismiss the notifications
    dismissNotify(id) {
      const index = this.notifications.findIndex((notify) => notify.id === id);
      index > -1 && this.notifications.splice(index, 1);
    },
  },
};
</script>

<style scoped>
p.form-success {
  color: #fff;
  background-color: #28a745 !important;
}
p.form-danger {
  color: #fff;
  background-color: #dc3545 !important;
}

@-webkit-keyframes timer {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes timer {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.notifications-container {
  position: fixed;
  z-index: 3000;
  bottom: 25px;
}
html[dir="ltr"] .notifications-container {
  right: 80px;
}
html[dir="rtl"] .notifications-container {
  left: 80px;
}
@media (max-width: 576px) {
  .notifications-container {
    width: calc(100% - 30px);
    max-width: 250px;
  }
}
.notifications-container .notification {
  position: relative;
  line-height: 1.5;
  color: #fff;
}
html[dir="ltr"] .notifications-container .notification {
  padding: 10px 28px 10px 14px;
}
html[dir="rtl"] .notifications-container .notification {
  padding: 10px 14px 10px 28px;
}
@media (min-width: 577px) {
  .notifications-container .notification {
    width: 250px;
  }
}
@media (max-width: 767px) {
  .notifications-container .notification {
    width: 100%;
  }
}
.notifications-container .notification.timer .disappearing-time {
  display: block;
  position: absolute;
  bottom: -3px;
  height: 3px;
  background-color: inherit;
  opacity: 0.6;
  -webkit-animation-name: timer;
  animation-name: timer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
html[dir="ltr"]
  .notifications-container
  .notification.timer
  .disappearing-time {
  left: 0;
}
html[dir="rtl"]
  .notifications-container
  .notification.timer
  .disappearing-time {
  right: 0;
}
.notifications-container .notification.success {
  background-color: var(--success-color);
}
.notifications-container .notification.danger {
  background-color: var(--danger-color);
}
.notifications-container .notification.warning {
  background-color: var(--warning-color);
}
.notifications-container .notification.info {
  background-color: var(--info-color);
}
.notifications-container .notification:not(:last-of-type) {
  margin-bottom: 10px;
}
.notifications-container .notification .fa {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
html[dir="ltr"] .notifications-container .notification .fa {
  right: 12px;
}
html[dir="rtl"] .notifications-container .notification .fa {
  left: 12px;
}
.notifications-container .notification .fa:hover {
  opacity: 1;
}
.notifications-container .notify-enter-active {
  -webkit-animation: 0.3s ease-in-out;
  animation: 0.3s ease-in-out;
}
html[dir="ltr"] .notifications-container .notify-enter-active {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
html[dir="rtl"] .notifications-container .notify-enter-active {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.notifications-container .notify-leave-active {
  animation: 0.3s ease-in-out reverse;
}
html[dir="ltr"] .notifications-container .notify-leave-active {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}
html[dir="rtl"] .notifications-container .notify-leave-active {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}
.notifications-container .notify-move {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
</style>
